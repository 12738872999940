import {EditorSDK, PageRef} from '@wix/platform-editor-sdk';
import {WIX_ECOM} from '@wix/app-definition-ids';
import {AnyFunction} from '@wix/wixstores-client-core';

export async function reinstallPage(sdk: EditorSDK, pageId: string, action: AnyFunction) {
  const pagesData = await sdk.pages.data.getAll('');
  const pageToRemove = pagesData.find((page) => page.tpaPageId === pageId);
  const pageRef: PageRef = {id: pageToRemove.id, type: 'DESKTOP'};
  await sdk.pages.data.update('', {
    pageRef,
    data: {managingAppDefId: WIX_ECOM},
  });
  await sdk.pages.remove('', {pageRef, shouldShowEditorRemovePanel: false});
  await action();
}
