import {EditorSDK} from '@wix/platform-editor-sdk';
import {Logger as WebBiLogger} from '@wix/web-bi-logger/dist/src/types';
import {clickToPreviewWishlistInMembersSfe} from '@wix/bi-logger-ec-sf/v2';

import {DependantApps} from '../../services/DependantApps';

export const showInstallNotification = async ({
  sdk,
  t,
  dependantApps,
  installMembersArea,
  webBiLogger,
}: {
  sdk: EditorSDK;
  t: (keyToTranslate: string) => string;
  dependantApps: DependantApps;
  installMembersArea: boolean;
  webBiLogger: WebBiLogger;
}) => {
  await sdk.editor.showUserActionNotification('', {
    message: installMembersArea
      ? t('settings.productPage.floatingNotification.membersAreaAdded')
      : t('settings.productPage.floatingNotification.wishlistAdded'),
    type: sdk.editor.NotificationType.Success,
    link: {
      caption: t('settings.productPage.floatingNotification.previewWishlist'),
      onClick: async () => {
        void webBiLogger.report(clickToPreviewWishlistInMembersSfe({})).catch(_.noop);
        await dependantApps.navigateToWishlist();
      },
    },
  });
};
