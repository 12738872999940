import {delay} from '@wix/wixstores-client-core/dist/src/utils/delay';
import {getAppVersion} from '@wix/wixstores-client-core/dist/src/app-version/appVersion';
import {EditorSDK} from '@wix/platform-editor-sdk';

const getAssetsUrl = () => `https://static.parastorage.com/services/wixstores-client-worker/${getAppVersion()}/assets`;

export const showInstallProgressBar = async (
  sdk: EditorSDK,
  t,
  shouldInstallMembers: boolean = true
): Promise<() => void> => {
  let currStep = 1;
  const baseTranslationKey = shouldInstallMembers
    ? 'settings.productPage.loadingModal.addingMembersArea'
    : 'settings.productPage.loadingModal.addingWishlist';
  const progressBarTitle = t(baseTranslationKey);
  const progressBarSubTitles = [
    t(`${baseTranslationKey}.firstSubtitle`),
    t(`${baseTranslationKey}.secondSubtitle`),
    t(`${baseTranslationKey}.thirdSubtitle`),
  ];
  const timeToWaitBetweenSteps = shouldInstallMembers ? 6000 : 3500;

  await sdk.editor.openProgressBar('', {
    title: progressBarTitle,
    totalSteps: 3,
    currentStep: currStep,
    stepTitle: progressBarSubTitles[0],
    image: `${getAssetsUrl()}/images/wishlist.svg`,
  });
  await delay(timeToWaitBetweenSteps);
  await sdk.editor.updateProgressBar('', {
    currentStep: ++currStep,
    stepTitle: progressBarSubTitles[1],
  });
  await delay(timeToWaitBetweenSteps);
  await sdk.editor.updateProgressBar('', {
    currentStep: ++currStep,
    stepTitle: progressBarSubTitles[2],
  });
  await delay(500);
  return () => sdk.editor.closeProgressBar('', {isError: false});
};

export const closeProgressBar = async (progressBarPromise: Promise<() => void>): Promise<void> => {
  const close = await progressBarPromise;
  await delay(500);
  close();
  await delay(500);
};
