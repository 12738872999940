import {getAppVersion} from '@wix/wixstores-client-core/dist/src/app-version/appVersion';

function getTranslationsForVersionAndLocale(version: string, locale: string) {
  return `https://static.parastorage.com/services/wixstores-client-worker/${version}/assets/locale/editorWorker/editorWorker_${locale}.json`;
}

export async function translateFunctionFactory(locale: string) {
  const version = getAppVersion();
  const translationsJson = await (await fetch(getTranslationsForVersionAndLocale(version, locale))).json();
  return (keyToTranslate: string): string => {
    return translationsJson[keyToTranslate];
  };
}
