import {EcomComponent} from '@wix/ecom-platform-sdk';
import {WIX_ECOM} from '@wix/app-definition-ids';

interface AppEcomComponentsData {
  [key: string]: EcomComponentsData;
}

export interface EcomComponentsData {
  ecomComponents: {
    [key in EcomComponent]?: {
      isInstalled: boolean;
    };
  };
  managingAppId?: string;
}

export class EcomPlatformInstallationState {
  private ecomComponentsData: AppEcomComponentsData = {};

  public addInstallAppComponentsData(appDefId: string, ecomComponents: EcomComponentsData) {
    this.ecomComponentsData[appDefId] = ecomComponents;
  }

  public getTheAppEcomComponents(appDefId: string): EcomComponent[] {
    return Object.keys(this.ecomComponentsData[appDefId].ecomComponents).map((comp: EcomComponent) => comp);
  }

  public checkIfAppIsOnEcom(appDefId: string) {
    return !!this.ecomComponentsData[appDefId];
  }

  public getTheAppManageAppId(): string {
    if (this.getInstalledAppDefIds().length === 1) {
      const verticalAppId = this.getInstalledAppDefIds()[0];
      return this.ecomComponentsData[verticalAppId].managingAppId ?? verticalAppId;
    } else {
      return WIX_ECOM;
    }
  }

  public removeApp(appDefId: string) {
    // eslint-disable-next-line no-prototype-builtins
    if (this.ecomComponentsData.hasOwnProperty(appDefId)) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete this.ecomComponentsData[appDefId];
    }
  }

  public hasInstalledApps() {
    return !!this.getInstalledAppDefIds().length;
  }

  public isMoreThanOneAppInstalled() {
    return this.getInstalledAppDefIds().length > 1;
  }

  public getInstalledAppDefIds() {
    return Object.keys(this.ecomComponentsData);
  }
}
