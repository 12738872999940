import {DataCapsule, WixStorageStrategy} from 'data-capsule';
import {dataStorageConfig, DataStorageKey} from '../constants';

export class DataStorage {
  private readonly dataCapsule: DataCapsule;

  constructor(metaSiteId: string) {
    this.dataCapsule = new DataCapsule({
      strategy: new WixStorageStrategy(),
      namespace: 'wix-stores',
      scope: {siteId: metaSiteId},
    });
  }

  public setData(key: DataStorageKey, value: any): Promise<void> {
    return this.dataCapsule.setItem(key, value);
  }

  public async getData(key: DataStorageKey): Promise<any> {
    let item;
    try {
      item = await this.dataCapsule.getItem(key);
    } catch {
      item = dataStorageConfig[key].defaultValue;
    }
    return item;
  }
}
