import {AppManifestBuilder, WidgetBuilder} from '@wix/app-manifest-builder';
import {StoresWidgetID} from '@wix/wixstores-client-core';

const MEMBERS_WIDGETS_IDS = [StoresWidgetID.WISHLIST_PAGE, StoresWidgetID.MY_ADDRESSES, StoresWidgetID.MY_ORDERS];

const configureMembersWidget = (widgetBuilder: WidgetBuilder) => {
  widgetBuilder.behavior().set({removable: false});
  widgetBuilder.gfpp().set('widgetPlugins', {behavior: 'HIDE'});
};

export const configureMembersWidgets = (appManifestBuilder: AppManifestBuilder) => {
  MEMBERS_WIDGETS_IDS.forEach((widgetId) => {
    appManifestBuilder.configureWidget(widgetId, configureMembersWidget);
  });

  return appManifestBuilder;
};
