import {Experiments} from './Experiments';
import ExperimentsKit from '@wix/wix-experiments';
import {STORES_PREMIUM} from '@wix/wixstores-client-core/dist/es/src/constants';

export class ExperimentsApi {
  private _experiments: Experiments;
  private _hasStoresPremium: boolean;

  constructor(private readonly instance?: string) {}

  public async fetch(fetchFromGql = true): Promise<void> {
    let experimentsResponse;
    if (fetchFromGql) {
      experimentsResponse = await fetch('/_api/wix-ecommerce-storefront-web/api', {
        method: 'post',
        body: JSON.stringify({
          query: require('!raw-loader!./getConfig.graphql').default,
          source: 'WixStoresWebClient',
          operationName: 'getConfig',
        }),
        headers: {
          Authorization: this.instance,
          'Content-Type': 'application/json; charset=utf-8',
        },
      })
        .then((data) => data.json())
        .then((data) => {
          this._hasStoresPremium = data?.data?.premiumFeatures?.some((feature) => feature.name === STORES_PREMIUM);
          return (data.data.experiments || []).reduce((acc, e) => {
            acc[e.name] = e.value;
            return acc;
          }, {});
        })
        .catch(() => {
          throw new Error('wix-ecommerce-storefront-web: got bad request from server');
        });
    } else {
      const experimentsKit = new ExperimentsKit({useNewApi: true});
      experimentsResponse = await experimentsKit.load('wixstores');
    }

    this._experiments = new Experiments(experimentsResponse);
  }

  public get experiments() {
    return this._experiments;
  }

  public get hasStoresPremium() {
    return this._hasStoresPremium;
  }

  public enabled(specName: string): boolean {
    return this.experiments.enabled(specName);
  }
}
