import {StoresWidgetID} from '@wix/wixstores-client-core/dist/es/src/constants';

export async function sliderWidthMigration(sdk) {
  const allComponentsRefs: ComponentRef[] = await sdk.document.components.getAllComponents('');
  const allComponents: ComponentSpec[] = await sdk.components.get('', {
    componentRefs: allComponentsRefs,
    properties: ['data'],
  });

  const mobileSliderGalleries: ComponentSpec[] = allComponents
    .filter((component) => component.data && component.data.widgetId === StoresWidgetID.SLIDER_GALLERY)
    .map((slider) => ({...slider, componentRef: {...slider.componentRef, type: 'MOBILE'}}));

  const sliderGalleryLayouts: ComponentSpec[] = await Promise.all(
    mobileSliderGalleries.map((slider) =>
      sdk.components.layout.get('', slider).then((layout: any) => ({...slider, layout}))
    )
  );

  return Promise.all(
    sliderGalleryLayouts
      .filter(({layout}) => layout.width === 280 && layout.x === 20)
      .map((slider: any) =>
        sdk.components.layout.update('', {
          componentRef: slider.componentRef,
          layout: {x: 0, width: 320},
        })
      )
  );
}
