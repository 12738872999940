import {EditorSDK} from '@wix/platform-editor-sdk';

export async function doTransaction(sdk: EditorSDK, fn: () => any, errorReporter?: (e: any) => any) {
  try {
    return await sdk.document.transactions.runAndWaitForApproval('', fn);
  } catch (e) {
    const isConflictError = await sdk.document.transactions.isConflictError('', e);
    if (isConflictError) {
      return await sdk.document.transactions.runAndWaitForApproval('', fn);
    }

    errorReporter?.(e);
  }
}
